<template>
  <div id="app" ref="app" :style="width">
    <router-view/>
  </div>
</template>

<script>

export default {
  watch:{

  },
  data() {
    return {
      width: '',

    }
  },
  created() {


  },
  mounted() {
    // localStorage.removeItem("store");

    // 页面刷新保留VueX状态（测试阶段）
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem("store"))
          )
      )
    }


    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  beforeDestroy() {
    localStorage.setItem("store", JSON.stringify(this.$store.state));
  },
  methods: {

  }
}
</script>

<style>
html, body {
  margin: 0;
  height: 100%;
}

#app {
  height: 100%;
  margin: 0 auto;
  max-width: 500px;
  background: #16181c;
}


</style>
