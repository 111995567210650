import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login/index.vue'),
    meta: {
      title: "贡献值系统"
    }
  },
  {
    path: '/Object',
    name: 'Object',
    component: () => import('@/views/Object/index.vue'),
    meta: {
      title: "项目"
    }
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: () => import('@/views/Detail/index.vue'),
    meta: {
      title: "贡献值明细"
    }
  },
  {
    path: '/Main',
    name: 'Main',
    component: () => import('@/views/Main/index.vue'),
    children: [
      {
        path: 'Home',
        component: () => import('@/views/Main/Page/Home/index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: 'Object',
        component: () => import('@/views/Main/Page/Object/index.vue'),
        meta: {
          title: '项目列表'
        }
      },
      {
        path: '/Exchange',
        component: () => import('@/views/Exchange/index.vue'),
        children: [
          {
            path: '',
            name: 'Exchange',
            component: () => import('@/views/Exchange/pages/index.vue'),
            meta: {
              title: '兑换市场'
            }
          },
          {
            path: 'AddrManage',
            name: 'AddrManage',
            component: () => import('@/views/Exchange/pages/addrManage.vue'),
            meta: {
              title: '地址管理'
            },
          },
          {
            path: 'AddAddr',
            name: 'AddAddr',
            component: () => import('@/views/Exchange/pages/addAddr.vue'),
            meta: {
              title: '添加地址'
            }
          },
          {
            path: 'Item/:id',
            name: 'ExchangeItem',
            component: () => import('@/views/Exchange/pages/item.vue'),
            meta: {
              title: '兑换商品'
            }
          },
          {
            path: 'Record',
            name: 'ExchangeRecord',
            component: () => import('@/views/Exchange/pages/exchangeRecord.vue'),
            meta: {
              title: '兑换记录'
            }
          }
        ]
      }, 
      
    ]
  },
   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  const info = localStorage.getItem("info");

  if (!info && to.path !== '/Login') {
    next('/Login');
  }  else {
    next();
  }
})


export default router
